import Dashboard from "../pages/private/Dashboard";
import SystemInfo from "./SystemInfo";
import { AiOutlineDashboard, AiOutlineIssuesClose } from "react-icons/ai";
import { FaWpforms, FaUserSecret } from "react-icons/fa";
import DocumentTypes from "../pages/private/document-types/DocumentTypes";
import DocumentTypesEdit from "../pages/private/document-types/DocumentTypes";
import DocumentTypesCreate from "../pages/private/document-types/DocumentTypesCreate";




const createLink = (
    title,
    hidden,
    component,
    Icon,
    path,
    permissions,
    children
) => ({ title, hidden, component, Icon, path, permissions, children });

const { systemCode } = SystemInfo;

export const mainPermissions = {
    dashboard: [`${systemCode}-view-dashboard`],
    accreditationProcess: [`${systemCode}-view-accreditation-process`, `${systemCode}-create-accreditation-process`, `${systemCode}-update-accreditation-process`, `${systemCode}-accreditation-process`],
}

const MenuLinks = [
    createLink('DashBoard', null, <Dashboard />, AiOutlineDashboard, '/dashboard', null),

    createLink('Tipos de documentos', null, null, FaWpforms, '/tipos-de-documentos', null, [
        createLink('Listar', null, <DocumentTypes />, null, '/tipos-de-documentos/listar', null),
        createLink('Editar', true, <DocumentTypesEdit />, null, '/tipos-de-documentos/:id', null),
        createLink('Crear', null, <DocumentTypesCreate />, null, '/tipos-de-documentos/crear', null),
    ]),
];

export default MenuLinks;