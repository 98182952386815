import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import handleChange from "../../../util/handleChange";
import update from 'immutability-helper';
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import AsyncSelect from 'react-select/async';
import mapValues from "../../../util/mapValues";
import handleLoadSelectOptions from "../../../util/handleLoadSelectOptions";
import useUsers from "../../../hooks/useUsers";
import { Button, Modal } from "react-bootstrap";
import useDocumentTypes from "../../../hooks/useDocumentTypes";

const DocumentTypesCreate = () => {

    const navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        days: 1,
        steps: [],
    });

    const [showDocumentTypes, setShowDocumentTypes] = useState(false);

    const [filters, setFilters] = useState({
        page: 1,
        name: '',
        perPage: 50
    });

    const [{ documentTypes, total, numberOfPages, loading: loadingDocumentTypes }, getRecords] = useDocumentTypes({ params: { ...filters }, options: { useCache: false } });

    const { setLoading, setCustomAlert } = useFeedBack();

    const [{ data: createData, loading }, createRecord] = useAxios({ url: `/document-types`, method: 'POST' }, { manual: true, useCache: false });

    const [{ users, loading: usersLoading }, getUsers] = useUsers({ options: { useCache: false, manual: true } });

    useEffect(() => {
        setLoading({
            show: loading,
            message: 'Creando el registro'
        })
    }, [loading]);



    useEffect(() => {
        if (createData) {
            setCustomAlert({
                show: true,
                severity: 'success',
                title: 'Operación Exitosa',
                message: 'El registro fue creado exitosamente.'
            });
            navigate('/tipos-de-documentos/listar');
        }
    }, [createData])


    const handleChange = (e) => {
        setData(oldData => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault();

        createRecord({ data });
    }

    const handleDocumentType = (documentType) => {
        setShowDocumentTypes(false);
    }

    return (
        <div>
            <div className="my-4 align-items-center justify-content-between d-flex">
                <h3>
                    Crear formulario
                </h3>
                {
                    <>
                        <Link to={"/tipos-de-documentos/listar"} className="btn btn-primary">
                            Volver al listado
                        </Link>
                    </>
                }
            </div>

            <form className="card p-4" onSubmit={handleSubmit}>
                <div className="text-end">
                    <button
                        onClick={() => setShowDocumentTypes(true)}
                        type="button"
                        className="btn btn-xs btn-primary"
                        title="Cargar desde otro tipo de documento"
                    >
                        Crear Copiar
                    </button>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label">
                                Nombre
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                value={data.name}
                                required
                                type="text"
                                className="form-control"
                                name="name"
                                onChange={handleChange}
                                placeholder="Ej. Acreditación personal copiapo."
                            />
                        </div>
                    </div>
                </div>
            </form>
            <Modal show={showDocumentTypes} onHide={() => setShowDocumentTypes(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Seleccione el tipo de documento a copiar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <input type="text" placeholder="Buscar por nombre..." className="form-control" value={filters?.name} onChange={(e) => {
                            setFilters(oldFilters => {
                                return {
                                    ...oldFilters,
                                    page: 1,
                                    name: e.target.value
                                }
                            })
                        }} />
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingDocumentTypes ?
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                Cargando...
                                            </td>
                                        </tr>
                                        :
                                        documentTypes?.length > 0 ?
                                            documentTypes?.map((documentType, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {documentType?.id}
                                                        </td>
                                                        <td>
                                                            {documentType?.name}
                                                        </td>
                                                        <td>
                                                            <button onClick={() => handleDocumentType(documentType)} className="btn btn-xs btn-danger">
                                                                descargar
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    No se encontrarón resultados.
                                                </td>
                                            </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDocumentTypes(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DocumentTypesCreate;